<template>
  <div class="pageContent">
    <searchHeader />
    <div class="mallProductionDetails">
      <div class="proInfo">
        <el-row>
          <el-col :span="14">
            <div class="proImage">
              <img :src="biddingResourceDetails.img" width="100%" />
            </div>
            <div class="proContent">
              <div class="desc">
                <!-- <p class="title">{{ biddingResourceDetails.commodity }}</p> -->
                <p class="count">
                  公示时间：{{ biddingResourceDetails.showTime | DateFormate }}
                </p>
                <p class="count">
                  竞价时间：{{
                    biddingResourceDetails.startTime | DateFormate
                  }}至{{ biddingResourceDetails.endTime | DateFormate }}
                </p>
                <p class="count">
                  商品分类：{{ biddingResourceDetails.className }}
                </p>
                <p class="count">
                  商品编码：{{
                    biddingResourceDetails.iconSys
                      ? biddingResourceDetails.iconSys
                      : "---"
                  }}
                </p>
                <!-- <p
                  class="count"
                  v-for="(item,index) of biddingResourceDetails.specificationsName"
                  :key="index"
                >商品属性：{{ item.label }}</p> -->
                <p class="count">
                  重量：{{ biddingResourceDetails.hangWeight
                  }}{{ biddingResourceDetails.unit }}
                  <span
                    class="item"
                    v-if="
                      biddingResourceDetails.tradeWay == 'P' &&
                        biddingResourceDetails.minPickWeight
                    "
                  >
                    <span class="itemTitle">&nbsp;&nbsp; 最小起订量：</span>
                    <span class="itemText"
                      >{{ biddingResourceDetails.minPickWeight
                      }}{{ biddingResourceDetails.unit }}</span
                    >
                  </span>
                </p>
                <p class="count" v-if="biddingResourceDetails.quantity">
                  数量：{{ biddingResourceDetails.quantity }}
                </p>
                <p class="count">
                  订货单价(含税）：{{ biddingResourceDetails.price }}元/{{
                    biddingResourceDetails.unit
                  }}
                </p>
                <p class="count">
                  合计(含税）：{{ biddingResourceDetails.jzPrice }}元
                </p>
                <p class="count">
                  起拍总价：{{ biddingResourceDetails.startPrice }}元
                </p>
                <p
                  class="count"
                  v-if="biddingResourceDetails.hangResourcesType == 'Q'"
                >
                  特殊要求描述：{{
                    biddingResourceDetails.manufacturer
                      ? biddingResourceDetails.manufacturer
                      : "---"
                  }}
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="operateButtons">
              <!-- <el-button type="danger" @click="registered()" v-if="selectMallGoodGetter.style=='1'&&!isRegistered">立即报名</el-button>
              <el-button type="info" disabled v-if="selectMallGoodGetter.style=='1'&&isRegistered">已报名</el-button>
              <el-button type="info" disabled v-if="selectMallGoodGetter.style>'2'">已结束</el-button>-->
              <el-button
                type="danger"
                v-if="
                  biddingResourceDetails.style != '流拍' &&
                    biddingResourceDetails.style != '已结束' &&
                    biddingResourceDetails.signUpStyle == '未报名'
                "
                @click="goProductionDetails()"
                >报名</el-button
              >
              <el-button
                type="danger"
                disabled
                v-if="
                  biddingResourceDetails.style != '已结束' &&
                    biddingResourceDetails.signUpStyle == '已报名'
                "
                >已报名</el-button
              >
              <el-button
                type="info"
                disabled
                v-if="biddingResourceDetails.style == '已结束'"
                >结束</el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <div class="addPriceMessageBox" v-if="selectMallGoodGetter.style=='2'">
        <div class="addPriceMessage">
          <p>我的竞拍动态</p>
        </div>
        <div v-if="myOrderList.length==0" class="grayColor noDatas">暂无数据</div>
        <div v-for="(item,index) in myOrderList" v-else :key="index" class="grayColor">出价{{ item.nowPrice }}元，预计获得佣金{{ earnMoney }}元</div>
      </div>
      <div class="addPriceMessageBox" v-if="selectMallGoodGetter.style=='2'">
        <div class="addPriceMessage">
          <p>竞拍动态</p>
        </div>
        <div v-if="orderList.length==0" class="grayColor noDatas">暂无数据</div>
        <div v-for="(item,index) in orderList" v-else :key="index" class="grayColor">{{ item.firmName|filterName }}出价{{ item.nowPrice }}元，预计获得佣金{{ earnMoney }}元</div>
      </div>-->
      <el-row>
        <el-col :span="24">
          <div class="proDetails">
            <p class="proDetailsTitle">商品详情</p>
            <div class="proDetailsContent">
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>商品分类：{{ biddingResourceDetails.className }}</p>
                </el-col>

                <el-col
                  :span="6"
                  v-for="(item,
                  index) of biddingResourceDetails.specificationsNames"
                  :key="index"
                >
                  <span class="item" :key="index">
                    <span class="itemTitle">商品属性：</span>
                    <span class="itemText">{{ item.label }}</span>
                  </span>
                </el-col>
                <el-col
                  :span="6"
                  v-if="biddingResourceDetails.hangResourcesType != 'Q'"
                >
                  <p>
                    生产厂家：{{
                      biddingResourceDetails.holdManufacturer
                        ? biddingResourceDetails.holdManufacturer
                        : "--"
                    }}
                  </p>
                </el-col>
                <el-col
                  :span="6"
                  v-if="biddingResourceDetails.hangResourcesType != 'Q'"
                >
                  <p>
                    产地：{{
                      biddingResourceDetails.placeOrigin
                        ? biddingResourceDetails.placeOrigin
                        : "--"
                    }}
                  </p>
                </el-col>
                <el-col
                  :span="6"
                  v-if="biddingResourceDetails.hangResourcesType != 'Q'"
                >
                  <p>
                    品牌：{{
                      biddingResourceDetails.brand
                        ? biddingResourceDetails.brand
                        : "--"
                    }}
                  </p>
                </el-col>
                <el-col :span="6">
                  <p>交收仓库：{{ biddingResourceDetails.sname }}</p>
                </el-col>
                <el-col :span="6">
                  <p>
                    竞拍方式：{{
                      biddingResourceDetails.auctionStyle | buyFormate
                    }}
                  </p>
                </el-col>
                <!--               
              </el-row>
                <el-row :gutter="20">-->
                <!-- <el-col :span="6">
                  <p>磅差:{{ biddingResourceDetails.poundsPoor }}{{biddingResourceDetails.unit}}</p>
                </el-col>-->

                <el-col :span="6">
                  <p v-if="biddingResourceDetails.hangResourcesType == 'X'">
                    入库时间：{{
                      biddingResourceDetails.putWarehousTime | DateFormateDay
                    }}
                  </p>
                  <p v-else>
                    预计入库时间：{{
                      biddingResourceDetails.putWarehousTime | DateFormateDay
                    }}
                  </p>
                </el-col>

                <!-- </el-row>
                <el-row :gutter="20">-->
                <el-col
                  :span="6"
                  v-if="this.sessionInfoGetter.isLogin == 'true'"
                >
                  <p>
                    业务负责人：{{
                      biddingResourceDetails.businessName
                        ? biddingResourceDetails.businessName
                        : "--"
                    }}
                  </p>
                </el-col>
                <el-col
                  :span="6"
                  v-if="this.sessionInfoGetter.isLogin == 'true'"
                >
                  <p>
                    联系电话：{{
                      biddingResourceDetails.businessPhone
                        ? biddingResourceDetails.businessPhone
                        : "--"
                    }}
                  </p>
                </el-col>
                <el-col :span="8">
                  <!-- selectMallGoodGetter.hangResourcesType=='Q'? -->
                  <p>
                    企业名称 ：{{
                      biddingResourceDetails.hangEnterpriseName
                        ? biddingResourceDetails.hangEnterpriseName
                        : "--"
                    }}
                  </p>
                </el-col>
                <!-- <el-col :span="24" v-if="biddingResourceDetails.hangResourcesType=='Q'&& biddingResourceDetails.manufacturer">
                  <p>特殊要求描述：{{ biddingResourceDetails.manufacturer ? biddingResourceDetails.manufacturer : '--' }}</p>
                </el-col> -->
                <!-- <el-col :span="24" v-if="priceStep.length!=0">
                  跌价式时间价格：
                  <span v-for="( item,index) of priceStep" :key="index">
                    {{ item.saleTime | DateFormate }}&nbsp;{{ item.salePrice }}元&nbsp;&nbsp;
                    <i v-if="index<priceStep.length-1" class="setStyle">;</i>
                  </span>
                </el-col> -->
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :span="24"
          v-if="biddingResourceDetails.hangResourcesType == 'X'"
        >
          <div class="proDetails">
            <p class="proDetailsTitle">质检报告</p>
            <div style="width:25%">
              <img :src="zhieport.report" alt width="100%" />
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- <div class="proImageInfo">
        <div class="imageInto">
          <p>商品详情</p>
          <el-row>
            <el-col :span="6">品名:{{ selectMallGoodGetter.variety }}</el-col>
            <el-col :span="6">生产厂家:{{ biddingResourceDetails.manufacturer }}</el-col>
            <el-col :span="6">交收仓库:{{ biddingResourceDetails.storageName }}</el-col>
            <el-col :span="6">包装方式:{{ biddingResourceDetails.packag }}</el-col>
            <el-col :span="6">磅差:{{ biddingResourceDetails.poundsPoor }}</el-col>
            <el-col :span="6">包装规格:{{ biddingResourceDetails.specificationsName }}</el-col>
            <el-col :span="6">入库时间:{{ biddingResourceDetails.putWarehousDay }}</el-col>
          </el-row>
        </div>
      </div>-->
      <el-dialog
        v-dialogDrag
        title="确定出价"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="400px"
      >
        <el-row>
          <el-col span="24">当前报价为6000,每次加价100,确定出价6100?</el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="goOrder()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 报名 -->
    <el-dialog
      v-dialogDrag
      title="确定报名"
      :close-on-click-modal="false"
      :visible.sync="SignUp.isGoSignUp"
      width="540px"
    >
      <el-row>
        <el-col :span="24"
          >当前起拍总价为{{ biddingResourceDetails.startPrice }},基准总价为{{
            biddingResourceDetails.jzPrice
          }},需要履约订金{{
            Number(biddingResourceDetails.marginPrice).toFixed(2)
          }}</el-col
        >
      </el-row>
      <el-form ref="dialogForm" :rules="biddingRules" :model="dialogForm">
        <el-form-item label="短信验证码" prop="code">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-input
                    v-model="dialogForm.code"
                    placeholder="短信验证码"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button
                    class="width-100"
                    :disabled="validBtnDisabled"
                    @click="sendValidNo()"
                    >{{ validButtonText }}</el-button
                  >
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="摘单类型"
          prop="type"
          v-if="biddingResourceDetails.hangResourcesType == 'Q'"
        >
          <el-select class="width-100" v-model="dialogForm.type">
            <el-option
              v-for="items in typeOptions"
              :key="items.value"
              :label="items.label"
              :value="items.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="持仓ID"
          prop="holdId"
          v-if="biddingResourceDetails.hangResourcesType == 'Q'"
        >
          <el-select class="width-100" v-model="dialogForm.holdId">
            <el-option
              v-for="items in holdCategoryOptions"
              :key="items.holdId"
              :label="items.holdId + '-' + items.className"
              :value="items.holdId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="资金密码" prop="password">
          <el-input
            v-model="dialogForm.password"
            type="password"
            placeholder="资金密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFrom('dialogForm')">取 消</el-button>
        <el-button
          type="primary"
          :loading="SignUp.loading"
          @click="goSignUp('dialogForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import searchHeader from "../base/header/searchHeader";
import md5 from "js-md5";
export default {
  components: {
    searchHeader
  },
  data() {
    var checkRequire = (rule, value, callback) => {
      const that = this;
      if (this.biddingResourceDetails.hangResourcesType == "Q" && value == "") {
        return callback(new Error("请先选择持仓ID或摘单类型"));
      } else {
        callback();
      }
    };
    return {
      preCount: 1,
      dialogVisible: false,
      proDetails: {
        color: 1,
        size: 1
      },
      typeOptions: [
        // SO：卖履约订金摘SA：卖仓单摘
        { value: "SO", label: "履约订金摘" },
        { value: "SA", label: "仓单摘" }
      ],
      zhieport: {
        applyHoldId: null,
        commodity: "",
        createTime: null,
        firmId: null,
        holdCode: "",
        holdId: null,
        institutionsId: null,
        name: "",
        quantity: null,
        report: "",
        sampleCode: "",
        status: null,
        unit: "",
        weight: null
      },
      holdCategoryOptions: [],
      isRegistered: true, //是否已报名 后期转计算属性
      orderList: [],
      allOrderList: [],
      myOrderList: [],
      biddingResourceDetails: {
        specifications: null,
        price: null, //单价
        packag: null, //包装方式
        poundsPoor: null //磅差
      },
      SignUp: {
        isGoSignUp: false,
        loading: false
      },
      right: {
        biddingSignup: false
      },
      validButtonText: "发送验证码",
      dialogForm: {
        code: null,
        password: null,
        holdId: null,
        type: null
      },
      biddingRules: {
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入资金密码", trigger: "blur" }
        ],
        holdId: [
          { required: false, message: "请选择持仓ID", trigger: "blur" },
          { validator: checkRequire, trigger: "blur" }
        ],
        type: [
          { required: false, message: "请选择摘单类型", trigger: "blur" },
          { validator: checkRequire, trigger: "blur" }
        ]
      },
      validBtnDisabled: false,
      priceStep: []
    };
  },
  computed: {
    ...mapGetters([
      "selectMallGoodGetter",
      "sessionInfoGetter",
      "selectFirmApplyCommodityGetter"
    ]),
    newPrice() {
      // 返回最新价
      let price = 0;
      if (this.orderList.length > 0) {
        price = this.orderList[0].nowPrice;
      } else {
        price = this.newPeCommodityGetter.startPrice;
      }
      return price;
    }
  },
  mounted() {
    // ws
    // this.getpai()
    // 查询详情
    this.param_queryBiddingResourceDetails();
  },
  beforeDestroy() {
    //取消订阅
    // const arr = [];
    // arr.push(this.$route.params.hangResourcesId);
    // const arrrr = {
    //   fid: "unsubscribe-req",
    //   planIds: arr
    // };
    // hqWs.send(arrrr);
  },
  watch: {
    "dialogForm.type": {
      handler(val, oldVal) {
        const that = this;
        if (that.biddingResourceDetails.hangResourcesType == "Q") {
          this.getCommodityCategoryss(val);
        }
      }
    }
  },
  methods: {
    getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.biddingSignup = this.isHasRight("tc-trade-auction-sign");
          if (!this.right.biddingSignup) {
            this.$EL_MESSAGE("您无此权限");
            return;
          }
          this.SignUp.isGoSignUp = true;
        }
      });
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http.postFront(protocol.param_authn_margin_sendCaptcha).then(response => {
        this.$EL_MESSAGE(response.data.message);
        const that = this;
        this.validBtnDisabled = true;
        this.setTime(60, that);
      });
    },
    getCommodityCategoryss(type) {
      console.log(this.biddingResourceDetails);
      // 判断是什么类型如果是求购单不用选择类型，如果是摘单需要传参
      const that = this;
      protocolFwd.param_queryHold.param.page = 0;
      protocolFwd.param_queryHold.param.size = 10000;
      if (type == "SO") {
        //不需要选择摘单类型
        protocolFwd.param_queryHold.param.holdType = ["Y"];
      } else if (type == "SA") {
        protocolFwd.param_queryHold.param.holdType = ["X", "B"];
      } else {
        protocolFwd.param_queryHold.param.holdType = null;
      }
      protocolFwd.param_queryHold.param.sellStatus = "T";
      protocolFwd.param_queryHold.param.auditHoldStatus = "T";
      protocolFwd.param_queryHold.param.specificationsId = null;
      protocolFwd.param_queryHold.param.holdStatus = null;
      protocolFwd.param_queryHold.param.specificationsName = this.biddingResourceDetails.specificationsName;
      protocolFwd.param_queryHold.param.unitId = this.biddingResourceDetails.unitId;
      protocolFwd.param_queryHold.param.storageId = this.biddingResourceDetails.storageId;
      http.postFront(protocolFwd.param_queryHold).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.holdCategoryOptions = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goProductionDetails() {
      if (
        sessionStorage.getItem("sessionStr") &&
        sessionStorage.getItem("userId")
      ) {
        if (
          sessionStorage.getItem("firmId") ==
            this.biddingResourceDetails.hangFirmId ||
          sessionStorage.getItem("firmId") ==
            this.biddingResourceDetails.pickFirmId
        ) {
          this.$EL_MESSAGE("您不允许报名自己的竞价单");
          return;
        }
        this.getRights();
        // if (this.selectMallGoodGetter.hangResourcesType == "Q") {
        //   this.getCommodityCategoryss();
        // }
      } else {
        this.$EL_MESSAGE("请先登录");
        this.$router.push("/login");
      }
    },
    resetFrom(formName) {
      this.SignUp.isGoSignUp = false;
      this.dialogForm = {
        code: null,
        password: null,
        holdId: null,
        type: null
      };
      //  this.$refs[formName].resetFields();
    },
    // 查询质检报告
    getQualityInspectionReport(id) {
      protocolFwd.param_queryQualityInspectionReportNoSession.param.holdId = id;
      http
        .postFront(protocolFwd.param_queryQualityInspectionReportNoSession)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            // this.tableDatas = value.content;
            if (value.content.length != 0) {
              this.zhieport = value.content[0];
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    goSignUp(formName) {
      let that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.SignUp.loading = true;
          protocolNJP.param_signUp.param.itemId = this.$route.params.hangResourcesId;
          protocolNJP.param_signUp.param.holdId = this.dialogForm.holdId;
          // protocolNJP.param_signUp.param.offerPrice = (Number(this.selectGood.startPrice)*0.3).toFixed(2)
          protocolNJP.param_signUp.param.code = this.dialogForm.code;
          protocolNJP.param_signUp.param.marginPassWord = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" +
              this.dialogForm.password
          );
          protocolNJP.param_signUp.param.type = this.dialogForm.type
            ? this.dialogForm.type
            : "BO"; //买履约订金摘BA：买全款摘SO：卖履约订金摘SA：卖仓单摘 求购单可选
          http.postNewJP(protocolNJP.param_signUp).then(response => {
            const { code, message, value } = response.data;
            this.resetFrom(formName);
            this.SignUp.loading = false;
            this.SignUp.isGoSignUp = false;
            this.$EL_MESSAGE(message);
            if (code == 0) {
              // 刷新报名数据
              setTimeout(() => {
                that.param_queryAuctionEnrolment();
                if (that.biddingResourceDetails.hangResourcesType == "Q") {
                  this.$router.push("/sellerCenter/sales/registeredBidding");
                } else {
                  this.$router.push("/buyerCenter/purchase/registeredBidding");
                }
              }, 500);
            }
          });
        }
      });
    },
    // 查询用户报名的竞价商品
    param_queryAuctionEnrolment() {
      protocolFwd.param_queryAuctionEnrolment.param = {
        isFinish: null,
        tradeStatus: null,
        payStatus: null,
        hangResourceType: null,
        page: 0,
        size: 1000,
        sort: null,
        firmId: this.sessionInfoGetter.firmId
      };
      http.postFront(protocolFwd.param_queryAuctionEnrolment).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.$store.commit("setSelectFirmApplyCommodity", value.content);
        } else {
        }
      });
    },
    goOrder() {
      this.$EL_MESSAGE({
        message: "恭喜你，报价成功",
        type: "success"
      });
      this.dialogVisible = false;
      this.$router.push("/mall/confirmOrder"); //
    },
    addPrice() {
      this.dialogVisible = true;
      console.log("去出价");
    },
    registered() {
      console.log("报名");
    },
    param_queryBiddingResourceDetails() {
      let that = this;
      protocolFwd.param_queryResourceDetails.param.hangResourcesId = this.$route.params.hangResourcesId;
      protocolFwd.param_queryResourceDetails.param.pickResourcesId = this.$route.params.pickResourcesId;
      http.postFront(protocolFwd.param_queryResourceDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          console.log(value, 999);
          let newVaslue = value;
          value.specificationsNames = this.attributeTrans(
            value.specificationsName
          );
          if (value.auctionStyle == 2) {
            let newArray = [];
            let salePriceArray = JSON.parse(value.salePrice);
            let saleTimeArray = JSON.parse(value.saleTime);
            for (let i = 0; i < salePriceArray.length; i++) {
              let obj = {};
              obj.saleTime = saleTimeArray[i];
              obj.salePrice = salePriceArray[i];
              that.priceStep.push(obj);
            }
          }
          if (value.isFinish == 0 && value.isFlow == 0) {
            value["style"] = "公示中";
          } else if (value.isFinish == 1 && value.isFlow == 0) {
            value["style"] = "竞拍中";
          } else if (value.isFinish == 2 && value.isFlow == 0) {
            value["style"] = "已结束";
          } else if (value.isFlow == 1) {
            value["style"] = "流拍";
          }
          value["signUpStyle"] = "未报名";
          for (let j = 0; j < this.selectFirmApplyCommodityGetter.length; j++) {
            const selectCommodity = this.selectFirmApplyCommodityGetter[j];
            if (selectCommodity.hangResourcesId == value.hangResourcesId) {
              value["signUpStyle"] = "已报名";
            }
          }
          value.jzPrice = util.floatMul(value.price, value.hangWeight);
          value.marginPrice = util.floatMul(value.jzPrice, value.marginRate);
          this.biddingResourceDetails = value;
          if (value.hangResourcesType == "X") {
            this.getQualityInspectionReport(value.hangHoldId);
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getpai() {
      const arr = [];
      arr.push(this.$route.params.hangResourcesId);
      const subscribe = {
        fid: "subscribe-req",
        planIds: arr
      };
      hqWs.send(subscribe);
      hqWs.register(this.DispathSocketEvent);
    },
    DispathSocketEvent(eventData) {
      switch (eventData.fid) {
        case "subscribe-resp": // 订阅成功
          break;
        case "lastprice-push": // 推送竞拍价格变动信息
          if (eventData.respList) {
            const data = eventData.respList;
            if (data.length === 1) {
              this.allOrderList.unshift(data[0]);
            } else {
              this.allOrderList = data;
            }
          } else {
            this.allOrderList = [];
          }
          if (this.allOrderList.length > 10) {
            this.orderList = this.allOrderList.slice(0, 10);
          } else {
            this.orderList = this.allOrderList;
          }
          const myArr = [];
          for (let i = 0; i < this.allOrderList.length; i++) {
            const element = this.allOrderList[i];
            if (
              Number(element.firmId) === Number(this.sessionInfoGetter.firmId)
            ) {
              myArr.push(element);
            }
          }
          if (myArr.length > 10) {
            this.myOrderList = myArr.slice(0, 10);
          } else {
            this.myOrderList = myArr;
          }
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.mallProductionDetails {
  // width: 80%;
  // min-width: 1300px;
  width: 1200px;
  margin: 0 auto;
}
.mallProductionDetails .proInfo {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.proInfo {
  position: relative;
  overflow: hidden;
}
.proInfo .proImage {
  float: left;
  margin-right: 15px;
  width: 260px;
  height: 260px;
  // background-color: #cccccc;
}
.proInfo .proImage p {
  line-height: 260px;
  text-align: center;
  color: #ffffff;
  font-size: 26px;
}
.proInfo .proImage img {
  width: 100%;
  margin: auto 0;
}
.proInfo .proContent {
  margin-left: 280px;
  line-height: 30px;
}
.proInfo .proContent .title {
  font-size: 18px;
  font-weight: 700;
}
.proInfo .proContent .desc {
  margin-top: 10px;
}
.proInfo .proContent .desc * {
  color: #666666;
}
.proInfo .proContent .desc .descList {
  overflow: hidden;
}
.proInfo .proContent .desc .descTitle {
  float: left;
}
.proInfo .proContent .desc .rushPrice,
.proInfo .proContent .desc .plusPrice {
  font-weight: 700;
  color: $commonThemeColor;
}
.proInfo .proContent .desc .marketPrice {
  text-decoration: line-through;
}
.proInfo .proContent .desc .rushProgress .alreadyRush {
  font-size: 12px;
  color: $commonThemeColor;
  font-style: italic;
  font-weight: 700;
}
.proInfo .operateButtons {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.proInfo .operateButtons .proCount {
  margin-right: 5px;
}
.proImageInfo {
  padding: 15px;
  margin-top: 20px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
}
.proImageInfo .imageInto {
  width: 100%;
  height: 100%;
}
.proImageInfo .imageInto p {
  font-size: 22px;
}
.addPriceMessageBox {
  padding: 15px;
  margin-top: 20px;
  height: 200px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  overflow: scroll;
}
.setStyle {
  font-style: normal;
}
.addPriceMessageBox .addPriceMessage {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  background-color: #cccccc;
}
.proDetails {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.proDetails {
  margin-top: 20px;
}
.proDetails .proDetailsTitle {
  margin-bottom: 10px;
  font-size: 16px;
  color: $commonThemeColor;
}
.proDetails .proDetailsContent {
  line-height: 35px;
  color: #666666;
}
</style>
